.Preload {
    background: url('/assets/images/under_construction_1.gif') no-repeat -9999px -9999px;
}

.LandContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Land {
    background: url('/assets/images/under_construction_2.gif');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    position: relative;
}

.LogoContainer {
    position: absolute;
    bottom: 1%;
    right: 1%;
    height: 10vw;
    width: 10vw;
    max-height: 120px;
    max-width: 120px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

button.ContactLink {
    background: none;
    border: none;
    outline: none;
    position: absolute;
    bottom: 3%;
    left: 22%;
    color: black;
    /* background: rgba(250, 250, 250, 0.6); */
    font-family: Inconsolata;
    font-size: 12px;
    font-weight: bold;
}

button.ContactLink:hover {
    cursor: pointer;
}

.Contact {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding-left: 6%;
    box-sizing: border-box;
}

@media(min-width: 600px) {
    button.ContactLink {
        font-size: 16px;
        bottom: 4%;
        left: 20%;
    }
}

@media(min-width: 800px) {
    button.ContactLink {
        font-size: 20px;
        bottom: 3%;
        left: 22%;
    }
}

@media(min-width: 1440px) {
    button.ContactLink {
        bottom: 3%;
        left: 22%;
        font-size: 1.4vw;
    }
}