body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html, body {
  height: 100%;
}

@font-face {
  font-family: Inconsolata;
  src: url('/assets/fonts/inconsolata-webfont.woff');
}