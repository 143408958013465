.Preload {
    background: url('/assets/images/under_construction_2.gif') no-repeat -9999px -9999px;
}

.JungleContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Jungle {
    background: url('/assets/images/under_construction_1.gif');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    position: relative;
}

.LogoContainer {
    position: absolute;
    top: 1%;
    left: 1%;
    height: 10vw;
    width: 10vw;
    max-height: 120px;
    max-width: 120px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

button.ContactLink {
    background: none;
    border: none;
    outline: none;
    position: absolute;
    bottom: 6%;
    right: 30%;
    color: white;
    font-family: Inconsolata;
    font-size: 12px;
}

button.ContactLink:hover {
    cursor: pointer;
}

.Contact {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding-right: 10%;
    box-sizing: border-box;
}

@media(min-width: 600px) {
    button.ContactLink {
        bottom: 8%;
        font-size: 12px;
    }
}

@media(min-width: 600px) {
    button.ContactLink {
        bottom: 9%;
        font-size: 14px;
    }
}

@media(min-width: 800px) {
    button.ContactLink {
        bottom: 9%;
        font-size: 15px;
    }
}

@media(min-width: 1024px) {
    button.ContactLink {
        bottom: 9%;
        font-size: 18px;
    }
}

@media(min-width: 1200px) {
    button.ContactLink {
        bottom: 9%;
        font-size: 22px;
    }
}

@media(min-width: 1440px) {
    button.ContactLink {
        bottom: 9%;
        font-size: 1.4vw;
    }
}