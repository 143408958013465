.ContactContainer {
    width: 100%;
    width: 280px;
    height: 100%;
    max-height: 550px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ContactContainer img {
    width: 100%;
}

.ContactContainer img:hover {
    cursor: pointer;
}

.Contact {
    height: 100%;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5% 0 0 2px;
    box-sizing: border-box;
}

.ContactImages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    margin-bottom: 5%;
    padding-top: 14px;
    padding-left: 8px;
}

.ContactContent {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    text-transform: uppercase;
    font-weight: bold;
    font-family: Inconsolata;
    padding-right: 10%;
    padding-top: 0;
    font-size: 16px;
    box-sizing: border-box;
}

.ContactElement {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    margin-top: 30px;
}

.ContactContent a {
    text-decoration: none;
    color: initial;
}

.ContactSeparator {
    width: 7px;
    border-bottom: 2px solid black;
}

@media (min-width: 600px) {
    .ContactContent {
        font-size: 18px;
    }
}

@media (min-width: 1900px) {
    .ContactContainer {
        width: 100%;
        max-width: 320px;
        height: 100%;
        max-height: 630px;
    }
    
    .ContactContent {
        font-size: 22px;
    }
}

@media (min-width: 2560px) {
    .ContactContainer {
        width: 100%;
        max-width: 380px;
        height: 100%;
        max-height: 750px;
    }
    
    .ContactContent {
        font-size: 24px;
    }
}

@media (min-width: 3840px) {
    .ContactContainer {
        width: 100%;
        max-width: 420px;
        height: 100%;
        max-height: 820px;
    }
    
    .ContactContent {
        font-size: 30px;
    }
}