body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html, body {
  height: 100%;
}

@font-face {
  font-family: Inconsolata;
  src: url('/assets/fonts/inconsolata-webfont.woff');
}
.App {
  text-align: center;
  height: 100%;
  min-height: 100%;
}
.Jungle_Preload__ho6iM {
    background: url('/assets/images/under_construction_2.gif') no-repeat -9999px -9999px;
}

.Jungle_JungleContainer__4WEMD {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Jungle_Jungle__EXyJS {
    background: url('/assets/images/under_construction_1.gif');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    position: relative;
}

.Jungle_LogoContainer__opeMT {
    position: absolute;
    top: 1%;
    left: 1%;
    height: 10vw;
    width: 10vw;
    max-height: 120px;
    max-width: 120px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

button.Jungle_ContactLink__2vCa6 {
    background: none;
    border: none;
    outline: none;
    position: absolute;
    bottom: 6%;
    right: 30%;
    color: white;
    font-family: Inconsolata;
    font-size: 12px;
}

button.Jungle_ContactLink__2vCa6:hover {
    cursor: pointer;
}

.Jungle_Contact__3TEEe {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding-right: 10%;
    box-sizing: border-box;
}

@media(min-width: 600px) {
    button.Jungle_ContactLink__2vCa6 {
        bottom: 8%;
        font-size: 12px;
    }
}

@media(min-width: 600px) {
    button.Jungle_ContactLink__2vCa6 {
        bottom: 9%;
        font-size: 14px;
    }
}

@media(min-width: 800px) {
    button.Jungle_ContactLink__2vCa6 {
        bottom: 9%;
        font-size: 15px;
    }
}

@media(min-width: 1024px) {
    button.Jungle_ContactLink__2vCa6 {
        bottom: 9%;
        font-size: 18px;
    }
}

@media(min-width: 1200px) {
    button.Jungle_ContactLink__2vCa6 {
        bottom: 9%;
        font-size: 22px;
    }
}

@media(min-width: 1440px) {
    button.Jungle_ContactLink__2vCa6 {
        bottom: 9%;
        font-size: 1.4vw;
    }
}
.Contact_ContactContainer__1YyuV {
    width: 100%;
    width: 280px;
    height: 100%;
    max-height: 550px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Contact_ContactContainer__1YyuV img {
    width: 100%;
}

.Contact_ContactContainer__1YyuV img:hover {
    cursor: pointer;
}

.Contact_Contact__367U1 {
    height: 100%;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5% 0 0 2px;
    box-sizing: border-box;
}

.Contact_ContactImages__xP6ta {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    margin-bottom: 5%;
    padding-top: 14px;
    padding-left: 8px;
}

.Contact_ContactContent__1wULz {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    text-transform: uppercase;
    font-weight: bold;
    font-family: Inconsolata;
    padding-right: 10%;
    padding-top: 0;
    font-size: 16px;
    box-sizing: border-box;
}

.Contact_ContactElement__3B3yA {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    margin-top: 30px;
}

.Contact_ContactContent__1wULz a {
    text-decoration: none;
    color: #000;
    color: initial;
}

.Contact_ContactSeparator__1ntT5 {
    width: 7px;
    border-bottom: 2px solid black;
}

@media (min-width: 600px) {
    .Contact_ContactContent__1wULz {
        font-size: 18px;
    }
}

@media (min-width: 1900px) {
    .Contact_ContactContainer__1YyuV {
        width: 100%;
        max-width: 320px;
        height: 100%;
        max-height: 630px;
    }
    
    .Contact_ContactContent__1wULz {
        font-size: 22px;
    }
}

@media (min-width: 2560px) {
    .Contact_ContactContainer__1YyuV {
        width: 100%;
        max-width: 380px;
        height: 100%;
        max-height: 750px;
    }
    
    .Contact_ContactContent__1wULz {
        font-size: 24px;
    }
}

@media (min-width: 3840px) {
    .Contact_ContactContainer__1YyuV {
        width: 100%;
        max-width: 420px;
        height: 100%;
        max-height: 820px;
    }
    
    .Contact_ContactContent__1wULz {
        font-size: 30px;
    }
}
.Logo_Logo__GhjS7 {
    width: 100%;
    height: 100%;
    max-width: 100px;
    max-height: 100px;
}

.Logo_Logo__GhjS7 img {
    width: 100%;
    height: 100%;
}

@media (max-height: 400px) {
    .Logo_Logo__GhjS7 {
        max-width: 40px;
        max-height: 40px;
    }
}
.Land_Preload__1b3-c {
    background: url('/assets/images/under_construction_1.gif') no-repeat -9999px -9999px;
}

.Land_LandContainer__10ufn {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Land_Land__9okiQ {
    background: url('/assets/images/under_construction_2.gif');
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    position: relative;
}

.Land_LogoContainer__2o9DW {
    position: absolute;
    bottom: 1%;
    right: 1%;
    height: 10vw;
    width: 10vw;
    max-height: 120px;
    max-width: 120px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

button.Land_ContactLink__wqqlB {
    background: none;
    border: none;
    outline: none;
    position: absolute;
    bottom: 3%;
    left: 22%;
    color: black;
    /* background: rgba(250, 250, 250, 0.6); */
    font-family: Inconsolata;
    font-size: 12px;
    font-weight: bold;
}

button.Land_ContactLink__wqqlB:hover {
    cursor: pointer;
}

.Land_Contact__VIFja {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding-left: 6%;
    box-sizing: border-box;
}

@media(min-width: 600px) {
    button.Land_ContactLink__wqqlB {
        font-size: 16px;
        bottom: 4%;
        left: 20%;
    }
}

@media(min-width: 800px) {
    button.Land_ContactLink__wqqlB {
        font-size: 20px;
        bottom: 3%;
        left: 22%;
    }
}

@media(min-width: 1440px) {
    button.Land_ContactLink__wqqlB {
        bottom: 3%;
        left: 22%;
        font-size: 1.4vw;
    }
}
