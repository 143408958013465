.Logo {
    width: 100%;
    height: 100%;
    max-width: 100px;
    max-height: 100px;
}

.Logo img {
    width: 100%;
    height: 100%;
}

@media (max-height: 400px) {
    .Logo {
        max-width: 40px;
        max-height: 40px;
    }
}